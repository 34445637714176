// StatisticsModal.js
import React, { useEffect, useState } from 'react';
import {
  LineChart,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Dot,
  ReferenceLine,
} from 'recharts';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import './StatisticsModal.css';
import { doc, getDoc } from 'firebase/firestore';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const StatisticsModal = ({ clientId, isOpen, onClose }) => {
  const [couponData, setCouponData] = useState([]);
  const [moodData, setMoodData] = useState([]);
  const [triggerData, setTriggerData] = useState([]);
  const [exposureData, setExposureData] = useState([]);
  const [journalData, setJournalData] = useState([]);
  const [sudsData, setSudsData] = useState([]);
  const [allHistoricalData, setAllHistoricalData] = useState([]);

  const [isValidMoodData, setIsValidMoodData] = useState(false);
  const [isValidTriggerData, setIsValidTriggerData] = useState(false);
  const [isValidExposureData, setIsValidExposureData] = useState(false);
  const [isValidJournalData, setIsValidJournalData] = useState(false);
  const [isValidSudsData, setIsValidSudsData] = useState(false);
  const [alias, setAlias] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading status

  const db = getFirestore();
  const localizer = momentLocalizer(moment);
  let isValidCouponData = false;

  // ****************** Trigger const ******************************
  const [dataTrigger, setDataTrigger] = useState([]);
  const [averageDistress, setAverageDistress] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [topCommonTriggers, setTopCommonTriggers] = useState([]);
  const [sortedCompulsions, setSortedCompulsions] = useState([]);
  const [averageDistressLast7Days, setAverageDistressLast7Days] = useState(0);
  const [compulsionReliefRatio, setCompulsionReliefRatio] = useState(0);
  const [isTrigger, setIsTrigger] = useState(false);
  const distressLevels = {
    '😄': 1,
    '🙂': 2,
    '😐': 3,
    '😟': 4,
    '😢': 5,
  };

  const emojiOptions = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
  };

  // *************************************************************************
  // ************* Fetch historical data for the specific client *************
  // *************************************************************************
  useEffect(() => {
    const fetchData = async () => {
      if (!clientId) return;

      try {
        // Existing Firestore document references
        const userDocRef = doc(db, 'users', clientId); // assuming 'users' collection stores aliases

        // Fetch user document
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setAlias(userDoc.data().alias || ''); // Assuming 'alias' is the field name
        } else {
          console.log('No user data found for this client.');
        }
        // Create references to Firestore documents
        const couponDocRef = doc(db, 'couponHistory', clientId);
        const moodDocRef = doc(db, 'moodTracker', clientId);
        const triggerDocRef = doc(db, 'ocdTriggers', clientId);
        const exposureDocRef = doc(db, 'exposureHierarchy', clientId);
        const journalDocRef = doc(db, 'journals', clientId);
        const sudsDocRef = doc(db, 'ocdSuds', clientId);

        // Fetch both documents in parallel
        const [
          couponDoc,
          moodDoc,
          triggerDoc,
          exposureDoc,
          journalDoc,
          sudsDoc,
        ] = await Promise.all([
          getDoc(couponDocRef),
          getDoc(moodDocRef),
          getDoc(triggerDocRef),
          getDoc(exposureDocRef),
          getDoc(journalDocRef),
          getDoc(sudsDocRef),
        ]);

        // Process suds data
        if (sudsDoc.exists()) {
          const data = sudsDoc.data();
          const formattedData = Object.keys(data).map(trigger => ({
            trigger,
            data: splitDataBySession(data[trigger]), // Split data by session
          }));
          setSudsData(formattedData);
          setIsValidSudsData(true);
          if (formattedData.length <= 0) {
            setIsValidSudsData(false);
          }
        } else {
          // console.log('No SUDS data found for this user.');
          setIsValidSudsData(false);
        }

        // Process coupon data
        if (couponDoc.exists()) {
          const couponHistory = couponDoc.data().history || [];
          setCouponData(couponHistory);
        } else {
          console.log('');
          setCouponData([]);
        }

        // Process journal data
        if (journalDoc.exists()) {
          const journalData = journalDoc.data().entries || {};
          const loadedEvents = Object.keys(journalData).map(date => {
            const entry = journalData[date];
            const emoji = emojiOptions[entry.score] || '😊'; // Default emoji if score not found
            return {
              title: `${emoji} ${entry.text}`, // Combine emoji and text
              start: new Date(date),
              end: new Date(date),
              text: entry.text,
              score: entry.score,
            };
          });
          setJournalData(loadedEvents);
          // console.log('Journal data:', journalData);
          setIsValidJournalData(true);
        } else {
          // console.log('No journal entries found for this user.');
          setIsValidJournalData(false);
        }

        // Process mood data
        if (moodDoc.exists()) {
          const moodData = moodDoc.data();
          setMoodData(moodData);
          setIsValidMoodData(true);

          const moodDataLength = Object.keys(moodData).length;

          if (moodDataLength > 0) {
            setIsValidMoodData(true);
          } else {
            setIsValidMoodData(false);
          }
        } else {
          // console.log('No mood data found for this user.');
          setIsValidMoodData(false);
        }

        // Process exposure data
        if (exposureDoc.exists()) {
          const exposureData = exposureDoc.data().exposures || [];
          setExposureData(exposureData);
          setIsValidExposureData(true);

          if (exposureData.length > 0) {
            setIsValidExposureData(true);
          } else {
            setIsValidExposureData(false);
          }
        } else {
          // console.log('No exposure data found for this user.');
          setIsValidExposureData(false);
        }

        // Process trigger data
        if (triggerDoc.exists()) {
          const triggerData = triggerDoc.data().triggers;

          setTriggerData(triggerData);
          //console.log('trigger data:', triggerData);
          calculateStatistics(triggerData);

          setIsValidTriggerData(true);
        } else {
          // console.log('No trigger data found for this user.');
          setIsValidTriggerData(false);
          setTriggerData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, [clientId, isOpen, db]);

  if (!isOpen) return null;

  const splitDataBySession = data => {
    const sessions = [];
    let currentSession = [];

    data.forEach(entry => {
      if (entry.time === 0 && currentSession.length > 0) {
        sessions.push(currentSession);
        currentSession = [];
      }
      currentSession.push(entry);
    });

    if (currentSession.length > 0) {
      sessions.push(currentSession); // Push the last session if it exists
    }

    return sessions;
  };

  // ************ Function to calculate statistics from triggers fetched data **************
  const calculateStatistics = data => {
    // Calculate average distress level
    const avgDistress =
      data.length > 0
        ? data.reduce((sum, entry) => sum + distressLevels[entry.distress], 0) /
          data.length
        : 0;
    setAverageDistress(avgDistress.toFixed(2));

    // Calculate data for the chart
    const chartData = data.reduce((acc, entry, index) => {
      const distressLevel = distressLevels[entry.distress] || 0;
      const total = acc.reduce((sum, data) => sum + data.distressLevel, 0);
      const averageDistress = ((total + distressLevel) / (index + 1)).toFixed(
        2
      );

      acc.push({
        entryNumber: index + 1,
        distressLevel,
        averageDistress,
      });

      return acc;
    }, []);
    setChartData(chartData);

    // Calculate trigger frequency
    const triggerFrequency = data.reduce((acc, entry) => {
      acc[entry.trigger] = (acc[entry.trigger] || 0) + 1;
      return acc;
    }, {});

    const topCommonTriggers = Object.entries(triggerFrequency)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3)
      .map(([trigger]) => trigger);
    setTopCommonTriggers(topCommonTriggers);

    // Calculate compulsion frequency
    const compulsionFrequency = data.reduce((acc, entry) => {
      acc[entry.compulsion] = (acc[entry.compulsion] || 0) + 1;
      return acc;
    }, {});

    const sortedCompulsions = Object.entries(compulsionFrequency)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3);
    setSortedCompulsions(sortedCompulsions);

    // Calculate distress over the last 7 days
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const recentData = data.filter(entry => new Date(entry.date) >= oneWeekAgo);
    const avgDistressLast7Days =
      recentData.length > 0
        ? recentData.reduce(
            (sum, entry) => sum + distressLevels[entry.distress],
            0
          ) / recentData.length
        : 0;
    setAverageDistressLast7Days(avgDistressLast7Days.toFixed(1));

    // Calculate compulsion/relief ratio
    const totalCompulsions = data.filter(entry => entry.compulsion).length;
    const totalReliefs = data.filter(entry => entry.relief).length;
    const ratio =
      totalCompulsions > 0 ? (totalReliefs / totalCompulsions).toFixed(2) : 0;
    setCompulsionReliefRatio(ratio);
  };

  // *************************************************************************
  // ****************************** RETURN ***********************************
  // *************************************************************************
  return (
    <>
      <div className='stat-modal'>
        <div className='modal-overlay' onClick={onClose}>
          {loading ? (
            <div className='loading-container'>
              <div className='loading-spinner'></div>
            </div>
          ) : (
            <div className='modal-content' onClick={e => e.stopPropagation()}>
              <button className='close-button' onClick={onClose}>
                X
              </button>
              <h2 className='dashboard-title' style={{ textAlign: 'center' }}>
                Statistics for {alias || 'User'}
              </h2>

              {/* ************************************************************************** */}
              {/* ***************** CHECK IF THERE ARE STAT TO DISPLAY ********************* */}
              {/* ************************************************************************** */}
              {(() => {
                isValidCouponData =
                  couponData &&
                  Array.isArray(couponData) &&
                  couponData.length > 0;
                if (
                  !(
                    isValidExposureData ||
                    isValidMoodData ||
                    triggerData.length > 0 ||
                    isValidSudsData ||
                    isValidJournalData ||
                    isValidCouponData
                  )
                ) {
                  return (
                    <div className='dashboard'>
                      <p>
                        No data to display! The client has not use any tool yet.
                      </p>
                    </div>
                  );
                }
              })()}

              {/* **************************************************** */}
              {/* ********************* JOURNAL ********************** */}
              {/* **************************************************** */}
              {(() => {
                // ********************** RETURN JOURNAL ********************
                return isValidJournalData ? (
                  <div className='dashboard'>
                    <h2
                      className='dashboard-title'
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#03306b',
                        color: 'white',
                        borderRadius: '5px',
                      }}
                    >
                      Journal
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                      Hover over an entry to see the content.
                    </p>
                    <Calendar
                      localizer={localizer}
                      events={journalData}
                      views={['month']}
                      defaultView='month'
                      // onSelectEvent={event => openModal(event.start)}
                      selectable
                      style={{ height: 500, width: 500, margin: 'auto' }}
                    />
                  </div>
                ) : (
                  <></>
                );
              })()}

              {/* **************************************************** */}
              {/* ***************** EXPSOURE TRACKER ***************** */}
              {/* **************************************************** */}
              {(() => {
                // *********************** Calculate statistics *******************
                const totalExposures = exposureData.length;
                const completedExposures = exposureData.filter(
                  exposure => exposure.completed
                ).length;
                const incompleteExposures = totalExposures - completedExposures;
                const completionRate = totalExposures
                  ? ((completedExposures / totalExposures) * 100).toFixed(1)
                  : 0;

                const averageDistressLevel = exposureData.length
                  ? (
                      exposureData.reduce(
                        (sum, exposure) => sum + Number(exposure.distress),
                        0
                      ) / exposureData.length
                    ).toFixed(1)
                  : 0;

                const distressReductionOverTime = exposureData
                  .filter(
                    exposure => exposure.completed && exposure.initialDistress
                  )
                  .reduce(
                    (sum, exposure) =>
                      sum + (exposure.initialDistress - exposure.distress),
                    0
                  );

                // ********************** RETURN TRIGGER ********************
                return isValidExposureData ? (
                  <div className='dashboard'>
                    <h2
                      className='dashboard-title'
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#03306b',
                        color: 'white',
                        borderRadius: '5px',
                      }}
                    >
                      Exposure Hierarchy Builder
                    </h2>

                    <div className='statistics-grid'>
                      <div className='statistics-item'>
                        <p>
                          <strong>Total Number of Exposures:</strong>
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '60px', fontWeight: 'bold' }}
                          >
                            {' '}
                            {totalExposures}
                          </span>
                        </p>
                      </div>
                      <div className='statistics-item'>
                        <p>
                          <strong>Completed vs. Incomplete Exposures:</strong>
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '25px', fontWeight: 'bold' }}
                          >
                            {completedExposures} vs {incompleteExposures}
                          </span>
                        </p>
                      </div>
                      <div className='statistics-item'>
                        <p>
                          <strong>Average Distress Level:</strong>
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '60px', fontWeight: 'bold' }}
                          >
                            {' '}
                            {averageDistressLevel}
                          </span>
                        </p>
                      </div>
                      <div className='statistics-item'>
                        <p>
                          <strong>Distress Reduction Over Time:</strong>
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '60px', fontWeight: 'bold' }}
                          >
                            {distressReductionOverTime}
                          </span>
                        </p>
                      </div>
                      <div className='statistics-item'>
                        <p>
                          <strong>Exposure Completion Rate:</strong>
                          <br />
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '30px', fontWeight: 'bold' }}
                          >
                            {' '}
                            {completionRate}%
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className='exposure-incomplete'>
                      <h4>Incomplete Exposures</h4>
                      <ul>
                        {exposureData
                          .filter(exposure => !exposure.completed)
                          .sort((a, b) => b.distress - a.distress)
                          .map(exposure => (
                            <li key={exposure.id}>
                              <span>
                                {exposure.title} (Distress: {exposure.distress})
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })()}

              {/* **************************************************** */}
              {/* ****************** TRIGGER TRACKER ***************** */}
              {/* **************************************************** */}
              {triggerData.length > 0 ? (
                <div className='dashboard'>
                  <h2
                    className='dashboard-title'
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#03306b',
                      color: 'white',
                      borderRadius: '5px',
                    }}
                  >
                    Trigger Tracker
                  </h2>
                  <div className='ritual-graph-trigger'>
                    <h3>Historical Distress Levels</h3>
                    <ResponsiveContainer width='100%' height={450}>
                      <ComposedChart data={chartData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='entryNumber' />
                        <YAxis ticks={[0, 1, 2, 3, 4, 5]} domain={[0, 5]} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='distressLevel' fill='#8884d8' />
                        <Line
                          type='monotone'
                          dataKey='averageDistress'
                          stroke='#82ca9d'
                          strokeWidth={2}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <div
                    className='container-trigger'
                    style={{
                      maxWidth: '400px',
                      borderRadius: '8px',
                      backgroundColor: '#1e1e1e',
                    }}
                  >
                    <p>
                      <strong>Distress Level Trend (Last 7 Days):</strong>{' '}
                      {averageDistressLast7Days}/5
                    </p>
                    <p>
                      <strong>Compulsion to Relief Ratio:</strong>{' '}
                      {compulsionReliefRatio}
                    </p>
                    <p>
                      <strong>Top 3 Most Common Triggers:</strong>
                    </p>
                    <ol>
                      {topCommonTriggers.length > 0 ? (
                        topCommonTriggers.map((trigger, index) => (
                          <li key={index}>{trigger}</li>
                        ))
                      ) : (
                        <li>No triggers recorded.</li>
                      )}
                    </ol>
                    <p>
                      <strong>Top 3 Most Frequent Compulsions:</strong>
                    </p>
                    <ol>
                      {sortedCompulsions.map(([compulsion]) => (
                        <li key={compulsion}>{compulsion}</li>
                      ))}
                    </ol>
                  </div>

                  

                  <table className='tracker1-table'>
                    <thead>
                      <tr>
                        <th>Trigger</th>
                        <th>Thought</th>
                        <th>Compulsion</th>
                        <th>Relief</th>
                        <th>Distress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {triggerData.map((entry, index) => (
                        <tr key={index}>
                          
                          <td>{entry.trigger}</td>
                          <td>{entry.thought}</td>
                          <td>{entry.compulsion}</td>
                          <td>{entry.relief}</td>
                          <td style={{ fontSize: '20px' }}>{entry.distress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}

              {/* **************************************************** */}
              {/* ****************** COUPON TRACKER******************* */}
              {/* **************************************************** */}
              {(() => {
                const isValidCouponData =
                  couponData &&
                  Array.isArray(couponData) &&
                  couponData.length > 0;

                const graphData = couponData.map((entry, index) => ({
                  date: `Week ${index + 1}`,
                  coupons: entry.coupons,
                }));

                // **************** Calculate statistics *******************
                const totalCouponsGiven = couponData.reduce(
                  (total, entry) => total + entry.coupons,
                  0
                );
                const totalWeeksTracked = couponData.length;
                const averageCouponsPerWeek = totalWeeksTracked
                  ? totalCouponsGiven / totalWeeksTracked
                  : 0;
                const maxCouponsInAWeek = Math.max(
                  ...couponData.map(entry => entry.coupons)
                );
                const minCouponsInAWeek = Math.min(
                  ...couponData.map(entry => entry.coupons)
                );
                const lastWeekCoupons =
                  couponData[totalWeeksTracked - 1]?.coupons || 0;

                // ********************** RETURN COUPON ********************
                return isValidCouponData ? (
                  <div className='dashboard'>
                    <h2
                      className='dashboard-title'
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#03306b', // Light gray background
                        color: 'white',
                        margin: 'auto',
                        borderRadius: '5px', // Optional rounded corners
                      }}
                    >
                      Reassurance Coupon
                    </h2>
                    <div className='coupon-graph'>
                      <h3>Reassurance Coupons Given</h3>
                      <ResponsiveContainer width='100%' height={300}>
                        <BarChart data={graphData}>
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis dataKey='date' />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey='coupons' fill='#8884d8' />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <br />
                    {/* Statistics */}
                    <div
                      className='container-trigger'
                      style={{
                        maxWidth: '400px',
                        borderRadius: '8px',
                        backgroundColor: '#1e1e1e',
                        boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                        marginTop: '0px',
                      }}
                    >
                      <p>
                        <strong>Total Coupons Given:</strong>{' '}
                        {totalCouponsGiven}
                      </p>
                      <p>
                        <strong>Average Coupons Per Week:</strong>{' '}
                        {averageCouponsPerWeek.toFixed(2)}
                      </p>
                      <p>
                        <strong>Maximum Coupons in a Week:</strong>{' '}
                        {maxCouponsInAWeek}
                      </p>
                      <p>
                        <strong>Minimum Coupons in a Week:</strong>{' '}
                        {minCouponsInAWeek}
                      </p>
                      <p>
                        <strong>Total Weeks Tracked:</strong>{' '}
                        {totalWeeksTracked}
                      </p>
                      <p>
                        <strong>Last Week's Coupons:</strong> {lastWeekCoupons}
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })()}

              {/* **************************************************** */}
              {/* ****************** SUDS TRACKER******************* */}
              {/* **************************************************** */}
              {(() => {
                const CustomDot = props => {
                  const { cx, cy, payload } = props;
                  let fill = 'lightblue';
                  let r = 2;

                  if (payload.level >= 80) {
                    fill = 'red';
                    r = 4;
                  } else if (payload.level <= 20) {
                    fill = 'green';
                    r = 4;
                  }

                  return <Dot cx={cx} cy={cy} r={r} fill={fill} />;
                };

                const lineColors = [
                  '#8884d8',
                  '#82ca9d',
                  '#ffc658',
                  '#8dd1e1', // Existing colors
                  '#d45087',
                  '#f95d6a',
                  '#ff7c43',
                  '#a05195',
                  '#665191',
                  '#2f4b7c',
                  '#4caf50',
                  '#ff5722',
                  '#607d8b',
                  '#795548',
                  '#9c27b0',
                  '#673ab7',
                  '#2196f3',
                  '#3f51b5',
                  '#00bcd4',
                  '#009688',
                  '#e91e63',
                  '#c2185b',
                  '#8bc34a',
                  '#ffeb3b',
                  '#ff9800',
                  '#ff5722',
                  '#00695c',
                  '#cddc39',
                  '#03a9f4',
                  '#b0bec5',
                  '#ff1744',
                  '#f50057',
                  '#d32f2f',
                  '#c2185b',
                ];

                // *******************************************************************************************
                // **** calculate the trend, comparative, and progress summary for a given ritual's data *****
                // *******************************************************************************************

                const calculateSummary = ritualData => {
                  const allLevels = ritualData.data.flatMap(session =>
                    session.map(entry => entry.level)
                  );
                  // console.log('aaaaaaa: ', ritualData.data.length);

                  // Calculate average anxiety level
                  const avgLevel =
                    allLevels.reduce((sum, level) => sum + level, 0) /
                    allLevels.length;

                  // Calculate highest and lowest anxiety levels
                  const highestLevel = Math.max(...allLevels);
                  const lowestLevel = Math.min(...allLevels);

                  // Determine the trend
                  let trend;

                  if (ritualData.data.length === 1) {
                    trend = '-';
                  } else {
                    trend =
                      avgLevel > highestLevel
                        ? 'up'
                        : avgLevel < lowestLevel
                        ? 'down'
                        : 'stable';
                  }

                  // Comparative analysis (simple comparison with the first session's average)
                  const initialSessionAvg =
                    ritualData.data[0].reduce(
                      (sum, entry) => sum + entry.level,
                      0
                    ) / ritualData.data[0].length;

                  let comparison;
                  let progressPercentage;
                  let milestoneReached;
                  if (ritualData.data.length === 1) {
                    comparison = ' (2 sessions needed!)';
                    progressPercentage = 0;
                  } else {
                    comparison =
                      avgLevel < initialSessionAvg ? 'Improved' : 'Worsened';
                    progressPercentage =
                      ((highestLevel - avgLevel) / highestLevel) * 100;
                    milestoneReached =
                      ritualData.data.length >= 3 &&
                      allLevels.slice(-3).every(level => level < avgLevel);
                  }

                  return {
                    avgLevel: avgLevel.toFixed(2),
                    trend,
                    comparison,
                    highestLevel,
                    lowestLevel,
                    progressPercentage: progressPercentage.toFixed(2),
                    milestoneReached,
                  };
                };

                return isValidSudsData ? (
                  <div className='dashboard'>
                    <h2
                      className='dashboard-title'
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#03306b', // Light gray background
                        color: 'white',
                        margin: 'auto',
                        borderRadius: '5px', // Optional rounded corners
                      }}
                    >
                      SUDS Tracker
                    </h2>
                    {sudsData.length > 0 ? (
                      sudsData.map((ritualData, ritualIndex) => {
                        const summary = calculateSummary(ritualData); // Get the summary for this ritual
                        // console.log('Ritual Data :', ritualData);
                        return (
                          <div key={ritualIndex} className='ritual-graph'>
                            <h3>
                              Recorded anxiety levels for{' '}
                              <span className='fear-color'>
                                {ritualData.trigger}
                              </span>
                            </h3>
                            <div className='responsive-wrapper'>
                              <ResponsiveContainer width='100%' height={300}>
                                <LineChart>
                                  <CartesianGrid strokeDasharray='3 3' />
                                  <XAxis
                                    type='number'
                                    dataKey='time'
                                    tickFormatter={value => `${value} min`}
                                    label={{
                                      value: 'Time (min)',
                                      position: 'insideBottomRight',
                                      offset: -5,
                                    }}
                                  />
                                  <YAxis
                                    domain={[0, 100]}
                                    ticks={[0, 20, 40, 60, 80, 100]}
                                    label={{
                                      value: 'Anxiety Level',
                                      angle: -90,
                                      position: 'insideLeft',
                                    }}
                                  />
                                  <Tooltip />
                                  <Legend />

                                  <ReferenceLine y={80} stroke='red' />
                                  <ReferenceLine y={20} stroke='green' />

                                  {ritualData.data.map(
                                    (session, sessionIndex) => {
                                      // Log each session's level data to the console
                                      /* console.log(
                                  `Ritual: ${ritualData.trigger}, Session ${
                                    sessionIndex + 1
                                  }`,
                                  session.map(entry => entry.level)
                                ); */

                                      return (
                                        <Line
                                          key={sessionIndex}
                                          data={session}
                                          type='monotone'
                                          dataKey='level'
                                          stroke={
                                            lineColors[
                                              sessionIndex % lineColors.length
                                            ]
                                          }
                                          strokeWidth={2}
                                          dot={<CustomDot />}
                                          name={`Session ${sessionIndex + 1}`}
                                        />
                                      );
                                    }
                                  )}
                                </LineChart>
                              </ResponsiveContainer>
                            </div>
                            {/* Trend, Comparative, and Progress summary for this ritual */}
                            <div className='trend-summary'>
                              <p>
                                <strong>Average Anxiety Level:</strong>{' '}
                                {summary.avgLevel} <br />
                                <br />
                                <strong>Trend:</strong>
                                <span className={`trend-${summary.trend}`}>
                                  {summary.trend === 'up'
                                    ? ' 📈 increased'
                                    : summary.trend === 'down'
                                    ? ' 📉 decreased'
                                    : summary.trend === '-'
                                    ? ' (2 sessions needed!)'
                                    : ' ⚖️ remained stable'}
                                </span>
                              </p>
                              <p>
                                <strong>Comparative Analysis: </strong>
                                {summary.comparison}
                              </p>
                              <p>
                                <p>
                                  <strong>Highest Level:</strong>{' '}
                                  {summary.highestLevel} |{' '}
                                  <strong>Lowest Level:</strong>{' '}
                                  {summary.lowestLevel}
                                </p>
                              </p>
                              <p>
                                <strong>Progress: </strong>
                                {summary.progressPercentage}% improvement
                                {summary.progressPercentage > 0 && (
                                  <div className='progress-bar-container'>
                                    <div
                                      className='progress-bar'
                                      style={{
                                        width: `${
                                          summary.progressPercentage > 0
                                            ? summary.progressPercentage
                                            : 0
                                        }%`,
                                        backgroundColor:
                                          summary.progressPercentage >= 0
                                            ? 'green'
                                            : 'red',
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </p>
                              {summary.milestoneReached && (
                                <p className='milestone'>
                                  🎉 <strong>Milestone Achieved!</strong>
                                  <br /> You've consistently recorded lower
                                  anxiety levels or achieved a goal!
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>No historical data available.</p>
                    )}
                  </div>
                ) : (
                  <></>
                );
              })()}

              {/* **************************************************** */}
              {/* ****************** MOOD TRACKER******************* */}
              {/* **************************************************** */}
              {(() => {
                const months = [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ];

                const moodEmojis = {
                  1: '😊',
                  2: '🙂',
                  3: '😟',
                  4: '😫',
                };

                // ************ Render the grid with days and months **************
                const renderGrid = () => {
                  return months.map((month, monthIndex) => (
                    <div key={month} className='month-row'>
                      {/* Month label */}
                      <div className='month-label'>{month}</div>
                      <div className='days-row'>
                        {Array.from({ length: 31 }, (_, day) => (
                          <div
                            key={day + 1}
                            className={`grid-cell mood-${
                              moodData[`${monthIndex}-${day + 1}`] || 0
                            }`} // Apply mood class
                          >
                            {moodEmojis[moodData[`${monthIndex}-${day + 1}`]] ||
                              ''}
                          </div>
                        ))}
                      </div>
                    </div>
                  ));
                };

                // ************* Calculate percentages for each emoji *************
                const calculateEmojiPercentages = () => {
                  const totalEntries = Object.values(moodData).length;
                  if (totalEntries === 0) return {};

                  const emojiCounts = Object.values(moodData).reduce(
                    (counts, mood) => {
                      counts[mood] = (counts[mood] || 0) + 1;
                      return counts;
                    },
                    {}
                  );

                  const emojiPercentages = {};
                  for (const mood in moodEmojis) {
                    const count = emojiCounts[mood] || 0;
                    emojiPercentages[mood] = (
                      (count / totalEntries) *
                      100
                    ).toFixed(1);
                  }

                  return emojiPercentages;
                };

                const emojiPercentages = calculateEmojiPercentages();

                //const isValidMoodData = true;

                // ********************** RETURN MOOD ********************
                return isValidMoodData ? (
                  <div className='dashboard'>
                    <h2
                      className='dashboard-title'
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#03306b', // Light gray background
                        color: 'white',
                        margin: 'auto',
                        borderRadius: '5px', // Optional rounded corners
                        marginBottom: '20px',
                      }}
                    >
                      Mood Tracker
                    </h2>
                    <br />
                    <br />
                    {/* Display emoji percentages */}
                    <div className='emoji-percentages'>
                      {Object.keys(moodEmojis).map(mood => (
                        <div key={mood}>
                          {moodEmojis[mood]}: {emojiPercentages[mood] || 0}%
                        </div>
                      ))}
                    </div>
                    <div className='container1-mood'>
                      <div className='calendar-grid'>{renderGrid()}</div>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatisticsModal;

import React, { useEffect, useState } from 'react';

import './OCDNews.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc } from 'firebase/firestore';

const OCDNews = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const articlesPerPage = 15; // Articles per page
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [userRole, setUserRole] = useState(''); // State to track user role

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      console.log("Auth State Changed. User:", user); // Log user object
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
  
      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('Role :',userDoc.data().role);
        } else {
          setUserRole(''); // Or 'guest' if you prefer
        }
      } else {
        setUserRole(''); // Clear role if not logged in
      }
    });
  
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          'https://www.ocdserenity.com/news-feed.json'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch news');
        }
        const data = await response.json();
        setArticles(data.articles);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNews();

    // Check if user is logged in
    // const checkLoginStatus = () => {
    //   // Replace with actual logic to check login status
    //   const userLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    //   setIsLoggedIn(userLoggedIn);
    // };

    // checkLoginStatus();
  }, []);

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  const filteredArticles = articles.filter(article => {
    const title = article.title.toLowerCase();
    const description = article.description.toLowerCase();
    const search = searchTerm.toLowerCase();
    return title.includes(search) || description.includes(search);
  });

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const highlightText = text => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'black', color: 'white' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <p>Error loading news: {error}</p>;
  }

  let NavbarComponent;
if (isLoggedIn) {
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }
} else {
  // Default Navbar when not logged in
  NavbarComponent = <Navbar />;
}

  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      {NavbarComponent}
      <div className='ocdnews'>
        <div className='search-bar'>
          <input
            type='text'
            placeholder="SEARCH e.g. CBT OR 'obsessive thoughts'"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='ocdnews-main-content'>
          <div className='content-main'>
            <div className='ocdnews-content'>
              <p>
                Our OCD News Feed keeps you informed with the latest research,
                treatments, and support resources for OCD. We only keep
                scientifically accurate and fact-based content and focus on
                articles that are research-driven, involve clinical studies, or
                provide scientifically grounded information.
              </p>
              {currentArticles.map((article, index) => (
                <div className='news-item' key={index}>
                  <span className='source'>{article.source.name}</span>
                  <br />
                  <span className='time'>
                    {formatDate(article.publishedAt)}
                  </span>
                  <br />
                  <a
                    href={article.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='news-title'>
                      🔗 {highlightText(article.title)}
                    </span>
                  </a>
                  <br />
                  <span className='data'>
                    {highlightText(article.description)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Pagination */}
        <div className='pagination'>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OCDNews;

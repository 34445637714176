import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TherapistRegistration.css';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Import your Firebase config file
import Navbar from './Navbar';
import Footer from './Footer';

const ClientRegistration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error1, setError1] = useState(null);
  const [myalert, setAlert] = useState(null);
  const [success1, setSuccess1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [decisionModalOpen, setDecisionModalOpen] = useState(false); // Modal for decision
  const [modalOpen, setModalOpen] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [waitingForEmailVerification, setWaitingForEmailVerification] =
    useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setIsPasswordVisible(prev => !prev);
  const navigate = useNavigate();

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);

    if (!value) {
      setEmailError('Email is required.');
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  // Password validation: at least 8 characters, 1 uppercase, 1 lowercase, 1 digit, and 1 special character
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handlePasswordChange = e => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setPasswordError('Password is required.');
    } else if (!passwordRegex.test(value)) {
      setPasswordError(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character (ONLY @$!%*?&).'
      );
    } else {
      setPasswordError('');
    }
  };

  const logEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
      console.log('Event logged successfully');
    } catch (error) {
      console.error('Error logging event:', error);
    }
  };

  const handleRegister = async e => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Check if email already exists in Firestore
      // console.error('EMAIL: ',email);
      const emailQuery = query(
        collection(db, 'users'),
        where('email', '==', email)
      );
      const emailSnapshot = await getDocs(emailQuery);
      // console.log('emailSnapshot: ', emailSnapshot);

      if (!emailSnapshot.empty) {
        // Email already exists
        setError(
          'This email is already registered. Please use a different email.'
        );
        setLoading(false);
        return;
      }

      // Open the decision modal
      setDecisionModalOpen(true);
    } catch (err) {
      console.error('Error checking email existence:', err);
      setError(
        'An error occurred while verifying the email. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDecision = option => {
    setDecisionModalOpen(false);
    if (option === 'withKey') {
      setModalOpen(true); // Open the secret key modal
    } else {
      registerAsIndividual();
    }
  };

  const handleSecretKeySubmit = async () => {
    setError1(null);

    if (secretKey.trim()) {
      try {
        const therapistsQuery = query(
          collection(db, 'users'),
          where('therapistSecretKey', '==', secretKey)
        );
        const querySnapshot = await getDocs(therapistsQuery);

        if (!querySnapshot.empty) {
          const therapistDoc = querySnapshot.docs[0];
          const therapistId = therapistDoc.id;

          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;

          await finalizeRegistrationClient(user, therapistId);
          navigate('/client-dashboard');

          /*
          await sendEmailVerification(user);
          setModalOpen(false);
          setAlert(
            'A verification email has been sent to your email. Please verify your email to continue.'
          );

          setWaitingForEmailVerification(true);
          pollEmailVerificationClient(user, therapistId);
          */
        } else {
          setError1('Invalid Therapist Secret Key. Please try again.');
          await logEvent(
            email,
            'client',
            'registration',
            'Invalid Therapist Secret Key.'
          );
        }
      } catch (error) {
        console.error('Error verifying secret key:', error);
        setError1('Failed to verify Therapist Secret Key. Please try again.');
      }
    } else {
      setError1('Please enter a valid Therapist Secret Key.');
    }
  };

  const handleSkip = async () => {
    setModalOpen(false);
    registerAsIndividual();
  };

  const registerAsIndividual = async () => {
    setError(null);
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await finalizeRegistration(user);
      navigate('/client-dashboard');

      /*
      await sendEmailVerification(user);
      setAlert(
        'A verification email has been sent to your email. Please verify your email to continue.'
      );

      setWaitingForEmailVerification(true);
      pollEmailVerification(user);
      */
    } catch (error) {
      console.error('Error registering individual:', error);
      setError('Failed to register. Please try again.');
      await logEvent(
        email,
        'individual',
        'registration',
        'Registration failed.'
      );
    } finally {
      setLoading(false);
    }
  };

  /*const pollEmailVerificationClient = async (user, therapistId) => {
    const intervalId = setInterval(async () => {
      try {
        await user.reload();
        if (user.emailVerified) {
          clearInterval(intervalId);
          setWaitingForEmailVerification(false);
          await finalizeRegistrationClient(user, therapistId);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error polling email verification:', error);
      }
    }, 3000);
  };
  */

  const finalizeRegistrationClient = async (user, therapistId) => {
    try {
      await setDoc(doc(db, 'users', user.uid), {
        alias: '',
        email,
        role: 'client',
        therapistId,
        comment: '',
        status: 'sponsored',
        createdAt: serverTimestamp(),
      });

      await logEvent(
        email,
        'client',
        'registration',
        'Client successfully registered and linked to therapist.'
      );

      setSuccess1('Registration complete!');
      setEmail('');
      setPassword('');
      setModalOpen(false);
    } catch (error) {
      console.error('Error finalizing registration:', error);
      setError1(
        'An error occurred during registration. Please contact support.'
      );
      await logEvent(
        email,
        'client',
        'registration',
        'An error occurred during registration.'
      );
    }
  };

/*
  const pollEmailVerification = async user => {
    const intervalId = setInterval(async () => {
      try {
        await user.reload();
        if (user.emailVerified) {
          clearInterval(intervalId);
          setWaitingForEmailVerification(false);
          await finalizeRegistration(user);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error polling email verification:', error);
      }
    }, 3000);
  };
  */

  const finalizeRegistration = async user => {
    try {
      await setDoc(doc(db, 'users', user.uid), {
        alias: '',
        email,
        role: 'individual',
        therapistId: null,
        comment: '',
        status: 'free',
        createdAt: serverTimestamp(),
      });

      await logEvent(
        email,
        'individual',
        'registration',
        'Individu registration complete!'
      );

      setSuccess('Registration complete!');
      setEmail('');
      setPassword('');
      setModalOpen(false);
    } catch (error) {
      console.error('Error finalizing registration:', error);
      setError(
        'An error occurred during registration. Please contact support.'
      );
      await logEvent(
        email,
        'individual',
        'registration',
        'An error occurred during registration.'
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className='therapist-registration'>
        <h2>Individual Registration</h2>
        <form onSubmit={handleRegister} className='registration-form'>
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className='error'>{emailError}</p>}
          </label>
          <label>
            Password:
            <div style={{ position: 'relative' }}>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '25px',
                  top: '50%',
                  fontSize: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {isPasswordVisible ? '👁️' : '🙈'}
              </span>
            </div>
            {passwordError && <p className='error'>{passwordError}</p>}
          </label>

          {loading ? (
            <p>Registering...</p>
          ) : !waitingForEmailVerification ? (
            <button
              type='submit'
              disabled={!!emailError || !!passwordError || !email || !password}
            >
              Register
            </button>
          ) : (
            <p>Waiting for email verification. Please check your inbox.</p>
          )}
          {myalert && <p className='myalert'>{myalert}</p>}
          {error && <p className='error'>{error}</p>}
          {success && <p className='success'>{success}</p>}
        </form>

        {/* Decision Modal */}
        {decisionModalOpen && (
          <div className='r-modal-overlay'>
            <div className='r-modal'>
              <h3>Registration Options</h3>
              <p style={{ textAlign: 'justify' }}>
                Would you like to register with a{' '}
                <strong>therapist's secret key</strong> or as an{' '}
                <strong>individual</strong>?
              </p>
              <p style={{ textAlign: 'justify' }}>
                The therapist's secret key is an 8-digit code provided by your
                therapist. This key allows your therapist to access your
                dashboard and support you on your journey to recovery.
              </p>
              <button onClick={() => handleDecision('withKey')}>
                With Secret Key
              </button>
              <button onClick={() => handleDecision('withoutKey')}>
                Without Secret Key
              </button>
            </div>
          </div>
        )}

        {modalOpen && (
          <div className='r-modal-overlay'>
            <div className='r-modal'>
              <h3>Enter Therapist Secret Key</h3>
              <input
                type='text'
                value={secretKey}
                onChange={e => setSecretKey(e.target.value)}
                placeholder='Therapist Secret Key'
              />
              <button onClick={handleSecretKeySubmit}>Continue</button>

              {error1 && <p className='error'>{error1}</p>}
              {success1 && <p className='success'>{success1}</p>}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ClientRegistration;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAudio } from './AudioContext'; // Import the context
import './Navbar.css'; // Import CSS for styling
import { signOut } from 'firebase/auth';
import { db, auth } from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions

function NavbarTherapist() {
  const { showAudioPlayer, handleMusicClick, stopMusic } = useAudio();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isTrialPeriod, setIsTrialPeriod] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const checkTrialPeriod = async () => {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(db, 'users', userId);

      try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.status === 'free') {
            setIsTrialPeriod(true);
          }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    checkTrialPeriod();
  }, []);

  const handleCancelSubscription = async () => {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(db, 'users', userId);

    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userStatus = docSnap.data().status;
        if (userStatus === 'paid') {
          await updateDoc(userDocRef, { status: 'cancelled' });
          alert('Subscription cancelled');
          handleLogout();
        } else {
          console.log('Cannot cancel subscription. User is not a paid member.');
        }
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }
    setShowCancelModal(false);
  };

  const handleDeleteAccount = () => {
    alert('Your account will be permanently deleted in the next 24 hours');
    setShowDeleteModal(false);
  };

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className='navbar'>
      <div className='logo'>OCD Serenity</div>
      <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className='line1'></div>
        <div className='line2'></div>
        <div className='line3'></div>
      </div>
      <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
        <ul className='nav-links'>
          <li>
            <Link to='/ocdnews'>📰 OCD News Feed</Link>
          </li>
          <li>
            <Link to='/agenda'>📅 OCD Agenda</Link>
          </li>
          <li className='menu-item'>
            <span>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/client-trigger-tracker'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/client-mood-tracker'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/client-exposure-tracker'>🚀 Exposure Hierarchy Builder</Link>
              </li>
              <li>
                <Link to='/client-suds-tracker'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/coupon'>🎟️ Reassurance Coupon</Link>
              </li>
              <li>
                <button
                  onClick={handleMusicClick}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  🎵 Relaxing Sound
                </button>
              </li>
              <li>
                <Link to='/journal'>✍️ Journaling</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/therapistdashboard'>📊 Dashboard</Link>
          </li>
          <li onClick={handleLogout}>
            <span>👤 Logout</span>
          </li>
          <li className='menu-item'>
            <span>⚙️ Settings</span>
            <ul className='submenu-1'>
              <li>
                <button
                  className='hover-red'
                  onClick={() => setShowCancelModal(true)}
                  disabled={isTrialPeriod}
                >
                  Cancel Subscription
                </button>
              </li>
              <li>
                <button
                  className='hover-red'
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Account
                </button>
              </li>
            </ul>
          </li>
        </ul>
        {showAudioPlayer && (
          <button
            onClick={stopMusic}
            style={{ position: 'absolute', top: '100px', right: '10px' }}
          >
            Stop Music
          </button>
        )}
        {showCancelModal && (
          <div className='modal'>
            <p>
              Are you sure you want to cancel your subscription? You will lose
              access to all premium features.
            </p>
            <button onClick={() => setShowCancelModal(false)}>Cancel</button>
            <button onClick={handleCancelSubscription}>Confirm</button>
          </div>
        )}
        {showDeleteModal && (
          <div className='modal'>
            <p>
              Are you sure you want to delete your account? You will lose access
              to all features and your data will be permanently deleted.
            </p>
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button onClick={handleDeleteAccount}>Confirm</button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavbarTherapist;

import React, { useState, useEffect } from 'react';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore functions
import './MoodTracker.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const MoodTrackerClient = () => {
  // ***********************************************************************************
  // ******************************* CHECK USER ROLE ***********************************
  // ***********************************************************************************
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('user role: ',userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // *******************************************************************************************
  // ******************************* CHECK IF USER LOGGED IN ***********************************
  // *******************************************************************************************
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  const [moodData, setMoodData] = useState({});
  const [loading, setLoading] = useState(true);
  const user = auth.currentUser; // Get the logged-in user

  useEffect(() => {
    const fetchMoodData = async () => {
      if (user) {
        const docRef = doc(db, 'moodTracker', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setMoodData(docSnap.data());
        } else {
          console.log('No mood data found!');
        }
        setLoading(false);
      }
    };

    fetchMoodData();
  }, [user]);

  const saveMoodDataToFirestore = async updatedMoodData => {
    if (user) {
      try {
        await setDoc(doc(db, 'moodTracker', user.uid), updatedMoodData);
      } catch (error) {
        console.error('Error saving mood data: ', error);
      }
    }
  };

  const handleClick = (month, day) => {
    const key = `${month}-${day}`;
    setMoodData(prevData => {
      const currentMood = prevData[key] || 0;
      const newMood = (currentMood % 5) + 1; // Cycle through 5 moods (1, 2, 3, 4, 5 with 5 being 'clear')

      const updatedMoodData = {
        ...prevData,
        [key]: newMood === 5 ? null : newMood, // Set to null if newMood is 5 (clear)
      };

      saveMoodDataToFirestore(updatedMoodData); // Save to Firestore
      return updatedMoodData;
    });
  };

  const handleClearData = async () => {
    if (
      window.confirm(
        'Are you sure you want to clear all your mood tracking data?'
      )
    ) {
      setMoodData({});
      await saveMoodDataToFirestore({}); // Clear Firestore data
      alert('Your mood data has been cleared.');
    }
  };

  // Emoji map for moods
  const moodEmojis = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
    null: '', // No emoji for cleared mood
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

 // Get today's date
const today = new Date();
//const today = new Date(2024, 11, 1); // Month is 0-indexed, so 10 is November
const todayKey = `${today.getMonth()}-${today.getDate()}`;

// Render the grid with days and months
const renderGrid = () => {
  return months.map((month, monthIndex) => (
    <div key={month} className="month-row">
      <div className="month-label">{month}</div>
      <div className="days-row">
        {Array.from({ length: 31 }, (_, day) => {
          const key = `${monthIndex}-${day + 1}`;
          const isToday = key === todayKey; // Check if this square represents today
          return (
            <div
              key={day + 1}
              className={`grid-cell mood-${
                moodData[key] || 0
              } ${isToday ? 'today-square' : ''}`} // Add 'today-square' class
              onClick={() => handleClick(monthIndex, day + 1)}
            >
              {moodEmojis[moodData[key]] || ''}
            </div>
          );
        })}
      </div>
    </div>
  ));
};

  if (loading) {
    return (<div className="loading-container">
        <div className="loading-spinner"></div>
      </div>);
  }

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  // *******************************************************************************************
  // ************************************* RETURN **********************************************
  // *******************************************************************************************
  return (
    <>
      {NavbarComponent}
      <div className='container-mood'>
        <h1>Pixel Mood Tracker</h1>
        <p>
          Track your daily mood and observe patterns over time. Click on each
          day to record your mood.
        </p>
        <div className='calendar-grid'>{renderGrid()}</div>
        <button className='clear-btn' onClick={handleClearData}>
          Clear All Data
        </button>
      </div>
      <Footer />
    </>
  );
};

export default MoodTrackerClient;

import React, { useState, useEffect } from 'react';
import './Home.css'; // Import your CSS file for styling
import calmMindImage from './calmMind-removebg-preview.png';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import EmailSignup from './EmailSignup';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig'; // Firebase setup
import { Link } from 'react-router-dom';

const Homepage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      {isLoggedIn ? <NavbarClient /> : <Navbar />} {/* Conditional rendering */}
      <div className='homepage-container'>
        <header className='homepage-header'>
          <h1>Tools That Empower You to Manage OCD Effectively</h1>
          <h2>
            For individuals and therapists, OCD Serenity provides simple,
            powerful resources to track progress, reduce distress, and live with
            peace.
            <br />
          </h2>
          <div className='header-content'>
            <div className='text-content'>
              <br />
              <br />
              <p>
                Gain more insight into your progress and take charge of your
                journey to reduce anxiety and stress.
                <br />
                <div className='cta-buttons'>
                  <Link to='/registerasclient'>
                    <button
                      className='cta-button'
                      aria-label='Start your free journey with OCD Serenity (Individuals)'
                    >
                      Start 14 Days Free Trial Today (Individuals)
                    </button>
                  </Link>
                  <Link to='/registerastherapist'>
                    <button
                      className='cta-button'
                      aria-label='Explore therapist tools on OCD Serenity'
                    >
                      See 30 Days Free Trial Therapist Tools
                    </button>
                  </Link>
                </div>
                <br />
                <p>
                  Or use most of the tools for free, without registering, right
                  now!
                </p>
                <br />
                At OCD Serenity, we prioritize your privacy and are proud to be
                HIPAA compliant, ensuring the highest standards of security and
                confidentiality for all users.
              </p>
              <br />
            </div>
            <div className='image-content'>
              <img src={calmMindImage} alt='calmMind' />
            </div>
          </div>
          <div>
            <br />
            <br />
            <br />
            <video
              autoPlay={true} // Use boolean value for autoPlay
              muted
              preload='auto'
              loop
              id='myVideo'
              style={{ width: '100%', height: 'auto' }}
            >
              <source
                src='https://storage.googleapis.com/ocdserenity_bucket/demo_therapists_v1.webm'
                type='video/webm'
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </header>
        <section className='homepage-header'>
          <h1>How We Can Help</h1>
          <p>
            OCD Serenity is designed to help you track and manage anxiety
            through insightful data and personalized progress tracking.
            <br />
            <br />
            Here’s what we offer:
          </p>
          <br />
          <h2>
            <strong>You Are A Therapist</strong>
          </h2>
          <p>
            Empowering Therapists to Support Their Clients with OCD, we offer a
            dedicated Therapist Dashboard to streamline your work and enhance
            client support. With this intuitive tool, you can:
          </p>
          <br />
          <p>
            ✅ <strong>Client Management</strong>: Easily list and follow your
            clients, tracking their progress and activities across all our
            tools.
          </p>
          <br />
          <p>
            ✅<strong>Guidance & Motivation</strong>: Send personalized
            notifications to provide encouragement, reminders, or tailored
            guidance to keep clients motivated on their journey.
          </p>
          <br />
          <p>
            ✅ <strong>Insightful Analytics</strong>: Access detailed statistics
            from the tools your clients use, including the Trigger Tracker, Mood
            Tracker, Exposure Hierarchy Builder, and Journal. These insights
            empower you to monitor progress and adapt your therapeutic approach
            effectively.
          </p>
          <br />
          <p>
            ✅ <strong>Comprehensive Features</strong>: As a therapist, you can
            also use all the tools available to individuals with OCD, equipping
            you with hands-on familiarity to better support your clients.
          </p>
          <br />
          <br />
          <p>
            All features are completely free for your clients, and their data is
            securely encrypted and stored in the cloud to meet HIPAA compliance
            standards. We ensures a safe, trusted environment where therapists
            and clients can focus on what matters most: progress and well-being.
          </p>
          <br />
          <EmailSignup />
          <br />
          <h2>
            <strong>You Have OCD</strong>
          </h2>
          <p>
            💪 We believe in your strength and resilience. 🌟 You’ve got this,
            and we’re rooting for you every day! 🎉
          </p>
          <br /><br />
            <video
              autoPlay={true} // Use boolean value for autoPlay
              muted
              preload='auto'
              loop
              id='myVideo'
              style={{ width: '100%', height: 'auto' }}
            >
              <source
                src='https://storage.googleapis.com/ocdserenity_bucket/demo_patient_v1.webm'
                type='video/webm'
              />
              Your browser does not support the video tag.
            </video>
          <br /><br /><br />
          <p>
            ✅ <strong>Dashboard</strong>: The Dashboard is your central hub for
            tracking progress, celebrating milestones, and managing tools that
            support your OCD journey. The dashboard empowers you to keep
            everything organized in one place, making it simpler to observe
            patterns, celebrate successes, and stay on track with your mental
            health journey.
          </p>
          <br />
          <p>
            ✅ <strong>OCD News Feed</strong>: Our OCD News Feed keeps you
            informed with the latest research, treatments, and support resources
            for OCD. Curated from trusted sources, this feed provides up-to-date
            articles and insights to help you stay connected with the OCD
            community.
          </p>
          <br />
          <p>
            ✅ <strong>Trigger Tracker</strong>: Use this tool to track your OCD
            triggers, intrusive thoughts, compulsions, and the relief or
            distractions you experience. This tracker helps you recognize
            patterns in your thoughts and behaviors, making it easier to monitor
            your progress over time.
          </p>
          <br />
          <p>
            ✅ <strong>Mood Tracker</strong>: The Pixel Mood Tracker is a simple
            and interactive tool to help you track your daily mood throughout
            the year.
          </p>
          <br />
          <p>
            ✅ <strong>Exposure Hierarchy Builder</strong>: The Exposure
            Hierarchy Builder helps you tackle your fears and anxieties
            step-by-step. This tool is designed to support you in facing
            difficult situations gradually, helping to build resilience and
            reduce anxiety over time.
          </p>
          <br />
          <p>
            ✅ <strong>SUDS Tracker</strong>: The Subjective Units of Distress
            Scale (SUDS) is a simple self-report tool used to measure the
            intensity of distress or discomfort you're experiencing. SUDS allows
            you to express how much distress you’re feeling in any given
            situation, making it easier to track progress over time.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
